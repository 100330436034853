$brk-base-colors: ();
$brk-base-colors: map-merge((
				"brand-primary": 			#0c48ad,
				"secondary": 					#2067dc,
				"white":              #fff,
				"black":              #000,
				"brk-dark-base":      #272727,
				"brk-base-1":         #185bc9,
				"brk-base-2":         #2668d0, //h1
				"brk-base-3":         #0F5AE0,
				"brk-base-4":         #d1ddf2,
				"brk-base-5":         #00f6ff,
				"brk-base-6":         #0B98F6,
				"brk-base-7":         #a7ebff,
				"brk-base-8":         #1600ba,// TODO: !!!
				"brk-secondary-1": 		#000a1a,
				"brk-secondary-2": 		#45474c,
				"brk-secondary-3": 		#0033e0,
				"brk-secondary-4": 		#00a8e0,
				"brk-secondary-5":    #6377DF,
				"brk-secondary-6": 		#0983e0,
				"text-base-color-1":  #546887, //p
				"text-base-color-2":  #0F5AE0,
				"text-base-color-3":  #f3f3f3,
      "form-color-1":         rgba(38, 104, 208, .2)
), $brk-base-colors);

@function safe-rgb($color) {
	@return unquote("#{red($color)}, #{green($color)}, #{blue($color)}");
}

/* ----------- Palette variable ----------- */
:root {
	@each $color, $value in $brk-base-colors {
		--#{$color}: #{$value};
	}

	// RGB
	@each $color, $value in $brk-base-colors {
		--#{$color}-rgb: #{safe-rgb($value)};
	}

	--b-radius: inherit;
}
/* ----------- Palette variable ----------- */